import { useUserStore } from "~/store/auth";

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const nuxtApp = useNuxtApp();

  if (process.server) return;

  userStore.setLoading(true);
  const rol = userStore.getLogin?.Modulos?.find(
    (module) => module.CodigoModulo === MSCmodule
  );

  if (rol && rol?.CodigoRol !== roles["admin"]) {
    userStore.setLoading(false);
    return navigateTo(nuxtApp.$localePath("/scheduling"), { replace: true });
  }
});
